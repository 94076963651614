<template>
  <CardWithSymbols v-if="current"
                   :backgroundColor="current.backgroundColor">
      <template slot="messages">
        <h1>{{title}}</h1>
        <markdown-component class="mt-4"
                            :source="current.text" />
      </template>
      <template slot="actions">
        <v-row justify="space-around">
          <v-btn v-if="showBackButton"
                 @click="back"
                 color="primary"
                 outlined>Atras</v-btn>
          <v-btn v-if="isLastGuide"
                 @click="ended"
                 color="primary">Continuar</v-btn>
          <v-btn v-else
                 @click="next"
                 color="primary">Siguiente</v-btn>
        </v-row>
      </template>
  </CardWithSymbols>
</template>

<script>
import GuideLogic from './guide-logic.vue';
import MarkdownComponent from '../../../components/markdown-component.vue';
import CardWithSymbols from '../../../components/CardWithSymbols.vue';

export default {
  name: 'guide-in-card-component',
  extends: GuideLogic,
  props: {
    title: {
      type: String,
      default: 'Guía',
    },
  },
  components: { MarkdownComponent, CardWithSymbols },
  computed: {
    showBackButton() {
      return !this.isFirstGuide
             || this.Guides.BEFORE_REGISTER === this.guideId;
    },
  },
};
</script>
